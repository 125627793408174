<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div class="search-form btn-2b5a95">
      <div
        class="l-w"
        :style="{
          height: $store.state.global.isSearchExtend_1
            ? ''
            : $store.state.global.searchFormHeight_1 + 10 + 'px',
        }"
      >
        <el-form
          label-position="right"
          :class="
            $store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'
          "
					:model="searchForm"
					@submit.native.prevent
        >
          <el-form-item label="院校:">
            <el-input 
							v-model.trim="searchForm.name" 
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
        </el-form>
      </div>
      <div class="r-w">
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="seach"
          :loading="!$store.state.global.isEndRequest"
          class="btn-2b5a95"
          >搜索</el-button
        >
				<el-button type="primary" @click="resetQuery">重置</el-button>
        <el-button
          type="primary"
          :icon="
            $store.state.global.isSearchExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          v-if="$store.state.global.searchExtend_1"
          class="btn-2b5a95"
          @click="onExtendClick('isSearchExtend_1')"
          >{{ $store.state.global.searchExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
      ref="multipleTable"
			stripe
			border
			:header-cell-style="{ background: '#044d8c', color: '#fff'}"
      style="width: 100%"
      :cell-style="{ padding: 0 }"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column label="流水号" prop="id" align="center" />
      <el-table-column label="技能名称" align="center" prop="name">
        <template slot-scope="scope" align="center">
          <el-button @click="btnShowDetail(scope.row)" type="text">
					{{
            scope.row.name
          }}
					</el-button>
        </template>
      </el-table-column>
      <el-table-column label="教学点" prop="schoolorgname" align="center" />
    </el-table>

    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>

    <!-- 查看院校详情 -->
    <el-dialog
			v-dialogDrag
			:close-on-click-modal='false'
      width="80%"
			title="详情："
      :visible.sync="showDetailModal"
      v-if="showDetailModal"
      append-to-body
    >
			<div class="show_detail_w">
					<div class="header">
			      <span>{{ dynamicValidateForm.vtechtoint.name }}</span>
			    </div>
			    <div class="content_w">
			      <img
			        :src="dynamicValidateForm.vtechtoint.imgurl ? $url.upload + dynamicValidateForm.vtechtoint.imgurl : dynamicValidateForm.vtechtoint.imgurl"
			        alt=""
			      />
						<img
						  :src="dynamicValidateForm.vtechtoint.model ? $url.upload + '/' + dynamicValidateForm.vtechtoint.model : dynamicValidateForm.vtechtoint.model"
						  alt=""
						/>
		
			      <div class="brief_w">
			        &nbsp &nbsp {{ dynamicValidateForm.vtechtoint.brief }}
			      </div>
						<div class="brief_w">
						  &nbsp &nbsp  <p>{{ dynamicValidateForm.vtechtoint.notice }}</p
						  >
						</div>
			    </div>
				</div>	
    </el-dialog>

    <!--添加-->
    <el-dialog
      class="btn-2b5a95"
			v-dialogDrag
			width="650px"
			:title="dialogTitle"
			:close-on-click-modal='false'
      :visible.sync="addShowAddModal"
      v-if="addShowAddModal"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        class="demo-dynamic form-item-w-100"
        status-icon
        :rules="rules"
        label-width="100px"
      >
        <el-form-item prop="companyorgname" label="所属分公司">
          <el-select
            v-model="dynamicValidateForm.companyorgname"
            placeholder="请选择"
            :disabled="!$store.state.login.isMaxAuth"
            clearable
            @change="onChangeCompany($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.companyList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="schoolorgname" label="所属教学点">
          <el-select
            v-model="dynamicValidateForm.schoolorgname"
            placeholder="请选择"
            clearable
            @change="onChangeSchool($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.schoolList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="班别名称:" prop="name">
          <el-input v-model="dynamicValidateForm.name" disabled />
          <el-button
            class="select-btn"
            type="primary"
            @click="
              onSelectData(
								fudaoclasstype_dialogresult,
                'showCollegenameModal',
                {
                  schoolorgid: dynamicValidateForm.schoolorgid,
                }
              )
            "
            >选择</el-button
          >
        </el-form-item>
        <el-form-item label="封面展示:" prop="imgurl">
          <el-input v-model="dynamicValidateForm.imgurl" disabled />
          <el-upload
            class="upload-demo"
            accept=".png,.jpg"
            :action="$store.state.global.baseUrl"
							multiple
            :show-file-list="false"
            :before-upload="beforeUpload"
            :http-request="(prop) => uploadFile('imgurl')"
          >
            <el-button type="primary" class="select-btn">点击上传</el-button>
            <div slot="tip" class="el-upload__tip"></div>
          </el-upload>
        </el-form-item>
        <el-form-item label="院校图片" prop="picurl">
          <el-input v-model="dynamicValidateForm.picurl" disabled />
          <el-upload
            class="upload-demo"
            accept=".png,.jpg"
            :action="$store.state.global.baseUrl"
            multiple
            :show-file-list="false"
            :before-upload="beforeUpload"
            :http-request="(prop) => uploadFile('picurl')"
          >
            <el-button type="primary" class="select-btn">点击上传</el-button>
            <div slot="tip" class="el-upload__tip"></div>
          </el-upload>
        </el-form-item>
        <el-form-item label="证书样板" prop="model">
          <el-input v-model="dynamicValidateForm.model" disabled />
          <el-upload
            class="upload-demo"
            accept=".png,.jpg"
            :action="$store.state.global.baseUrl"
            multiple
            :show-file-list="false"
            :before-upload="beforeUpload"
            :http-request="(prop) => uploadFile('model')"
          >
            <el-button type="primary" class="select-btn">点击上传</el-button>
            <div slot="tip" class="el-upload__tip"></div>
          </el-upload>
        </el-form-item>
				<el-form-item label="简介:" prop="brief">
				  <el-input 
						type="textarea"
						rows="6"
						v-model.trim="dynamicValidateForm.brief" />
				</el-form-item>
        <el-form-item label="报读须知:">
          <el-input 
						type="textarea"
						rows="6"
						v-model.trim="dynamicValidateForm.notice" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="addSubmitAddOrEditForm('dynamicValidateForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>
    <!-- 选择院校 -->
    <el-dialog
      class="btn-2b5a95"
      width="1200px"
			v-dialogDrag
			:close-on-click-modal='false'
      @close="onCloseSelect"
      title="选择院校："
      :visible.sync="showCollegenameModal"
      v-if="showCollegenameModal"
      append-to-body
    >
			<el-button type="primary" @click="onConfirmCollegename">确定</el-button>
     <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="院校:" prop="name">
              <el-input 
								v-model.trim="selectSearchForm.name" 
								@keyup.enter.native="onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({}, selectSearchForm)
              )"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
						 @click="onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({schoolorgid: dynamicValidateForm.schoolorgid}, selectSearchForm)
              )"
            >搜索</el-button
          >
					<el-button type="primary" @click="handleQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>
      <el-table
        :data="selectTable.list"
        style="width: 100%"
        ref="moneyTable"
				stripe
				border
				:header-cell-style="{ background: '#044d8c', color: '#fff'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="流水号" prop="id" align="center" />
        <el-table-column label="班别名称" prop="name" align="center" />
      </el-table>
      <el-pagination
				background
				@current-change="
				 (val, data) =>
				   handleSelectChange(val, Object.assign({schoolorgid: dynamicValidateForm.schoolorgid}, selectSearchForm))
				"
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="selectTable.totalcount"
      ></el-pagination>
    </el-dialog>
		
   
		
  </div>
</template>

<script>
import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import Export from "@/components/Export/index.vue";
import Import from "@/components/Import/index.vue";
import {
  techtoint_list,
  teachoint_input,
  teachoint_save,
  techtoint_show,
  techtoint_delete,
  collegetoint_export,
  collegetoint_exportexcel,
  collegetoint_exportsave,
  collegetomajor_importsave,
  collegetocourse_importsave,
  collegetocourse_list,
  collegetomajor_delete,
  collegetocourse_save,
  collegetocourse_delete,
  collegetomajor_save,
	fudaoclasstype_dialogresult,
} from "@/request/api/allChildrenProject";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";
import {
  _pictureupload_save,
  _filedownload_save,
  _collegemajorenroll_dialogresult,
  _collegemajor_dialogresultall,
} from "@/request/api/public";
import { _collegetype } from "@/assets/js/filedValueFlag";
export default {
  mixins: [part],
  name: "college",
  components: { Export, Import },
  props: {
   fudaoclasstype_dialogresult: {
      default: () => fudaoclasstype_dialogresult,
    },
  },
  data() {
    //数值表单规则
    var checkfield = (rule, value, callback) => {
      if (!(value + "")) {
        return callback(new Error("必填字段"));
      } else {
        if (isNaN(Number(value))) {
          callback(new Error("请输入数字值"));
        } else {
          callback();
        }
      }
    };
    return {
			addmultipleSelection:[],
			dialogTitle:null,//专业标题
			majorTale:'',
      itemId: null, //首表格项id
      searchForm: {},
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      addShowAddModal: false, // 添加
			deleteShowAddModal: false,//删除
      showDetailModal: false, // 查看详情
      multipleSelection: [], //选中的表格项
      dynamicValidateForm: {}, //表单
      rules: {
        schoolorgname: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        companyorgname: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        scorefile: [{ trigger: "blur", message: "必填字段", required: true }],
        name: [{ trigger: "blur", message: "必填字段", required: true }],
        imgurl: [{ trigger: "blur", message: "必填字段", required: true }],
        majornames: [{ trigger: "blur", message: "必填字段", required: true }],
        brief: [{ trigger: "blur", message: "必填字段", required: true }],
        picurl: [{ trigger: "blur", message: "必填字段", required: true }],
        model: [{ trigger: "blur", message: "必填字段", required: true }],
        //notice: [{ trigger: "blur", message: "必填字段", required: true }],
        coursename: [{ trigger: "blur", message: "必填字段", required: true }],
        majorname: [{ trigger: "blur", message: "必填字段", required: true }],
      }, //表单规则
      dialogTitle: "", //对话框标题
      fds: new FormData(),
      fileList: [], //上传的文件列表，仅作展示
      file: new FormData(), //上传的文件表单
      resTipC: "", //响应提示 object
      showCollegenameModal: false, //选择院校
      selectPageInfo: {
        pageindex: 1,
        pagesize: 10,
      },
      selectTable: {},
      selectTableUrl: "",
      selectSearchForm: {},
      //专业
      showCollegemajorModal: false,
      showCollegemajor: false, //专业
			deleteshowCollegemajor: false,//修改专业
      showImportMajor: false, //导入专业
      majorRes: [], //专业数据
      //课程列表
      showImportCourse: false, //导入课程
      showAddCourse: false, //新增课程
      courseRes: {}, //课程数据
      showCourseList: false,
			collegeid:null,
    };
  },
  created() {
    this.getDataList();
    this.org_combox();
    this.org_comboxchild(this.dynamicValidateForm.companyorgid);
  },
  methods: {
    ...mapActions("global", ["onExtendClick", "isExpand"]),
    ...mapActions("selectData", ["org_combox", "org_comboxchild"]),
    // 文件上传改变的钩子
    handleChange(file, fileList) {
      this.file = new FormData();
      this.file.append("scorefile", file.raw); // 传文件
      this.fileList = [{ name: file.name, url: file.name }];
      this.dynamicValidateForm.scorefile = file.raw.name;
    },
    // 文件上传删除
    onScorefileRemove(file, fileList) {
      this.dynamicValidateForm.scorefile = "";
      this.file.delete("scorefile");
    },
    //改变分公司 change
    onChangeCompany(val, form) {
      this[form].schoolorgname = "";
      this[form].companyorgid = val;
      this.org_comboxchild(val);
    },
    onChangeSchool(val, form) {
      this[form].schoolorgid = val;
    },
		querySelect() {
			this.selectSearchForm = {
				name: null
			}
		},
		handleQuery() {
			this.querySelect();
			this.onSelectData(this.selectTableUrl, null, null)
		},
		reset() {
			this.searchForm = {
				name: null
			}
		},
		resetQuery() {
			this.reset();
			this.getDataList();
		},
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },

    //列表查询 api
    getDataList() {
      myRequest({
        url: techtoint_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },

    //查看详情 - 显示专业课程 show
    onShowMajornames(id) {
      this.collegetocourseList(id);
    },
	
    // 查看详情 api
    btnShowDetail(row) {
      myRequest({
        url: techtoint_show,
        data: {
          id: row.id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.showDetailModal = true;
          this.dynamicValidateForm = res.data.data;
        }
      });
    },
    //添加click
    btnAdd() {
      this.dialogTitle = "添加";
      this.dynamicValidateForm = {
        schoolorgid: this.$store.state.login.userinfo.schoolorgid,
        companyorgid: this.$store.state.login.userinfo.companyorgid,
        companyorgname: this.$store.state.login.userinfo.companyorgname,
        schoolorgname: this.$store.state.login.userinfo.schoolorgname,
        imgurl: "",
        picurl: "",
        model: "",
				collegemajorid: this.dynamicValidateForm.collegemajorid,//collegemajorid专业流水号
				collegeid:this.dynamicValidateForm.collegeid//院校流水号
      };
     // console.log(this.dynamicValidateForm,this.$store.state)
      this.org_comboxchild(this.dynamicValidateForm.companyorgid);
      this.addShowAddModal = true;
    },
    //修改click
    btnEdit() {
      this.dialogTitle = "修改";
      selectCheck(this.multipleSelection, "修改", false, () => {
        this.itemId = this.multipleSelection[0].id;
        this.editRoutePage();
				 this.addShowAddModal = true;
      });
			
    },

    //删除click
    btnDel() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delTableItem(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //刷新 click
    btnReload() {
      this.getDataList();
    },
    //修改路由页 api
    editRoutePage() {
      myRequest({
        url: teachoint_input,
        data: {
          id: this.itemId,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.dynamicValidateForm = res.data.data.ttechtoint;
          this.org_comboxchild(this.dynamicValidateForm.companyorgid);
          this.showAddModal = true;
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
          delete this.dynamicValidateForm.inserttime; //inserttime 该字段不用传
          delete this.dynamicValidateForm.isoktime; //isoktime 该字段不用传
        }
      });
    },

    //提交-（添加，修改） api
    addSubmitAddOrEditForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          myRequest(
            {
              method: "post",
              url: teachoint_save,
              data: this.$qs.stringify(this.dynamicValidateForm, {
                arrayFormat: "repeat",
              }),
            },
            {
              isRefTableList: true,
              that: this,
							modal: "addShowAddModal",
            }
          );
        }
      });
    },
    //删除 api
    delTableItem(idArr) {
      myRequest(
        {
          method: "post",
          url: techtoint_delete,
          data: this.$qs.stringify(
            {
              id: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },
    //确认院校 click
    onConfirmCollegename() {
      selectCheck(this.multipleSelection, "选择", false, () => {
        this.dynamicValidateForm.fudaoclasstypeid = this.multipleSelection[0].id;
        this.dynamicValidateForm.name = this.multipleSelection[0].name;
        this.$set(
          this.dynamicValidateForm,
          "name",
          this.multipleSelection[0].name
        );
				this.showCollegenameModal = false;
				this.multipleSelection = [];
				this.querySelect();
      });
    },
    //上传文件前 before
    beforeUpload(file) {
      this.fds.append("upfile", file); // 传文件
    },
    //上传文件 upload
    uploadFile(prop) {
      myRequest({
        method: "post",
        url: _pictureupload_save,
        data: this.fds,
      }).then((res) => {
        if (res.data.code === "200") {
          this.dynamicValidateForm[prop] = res.data.url;
        }
      });
    },
    //切换选项表格页
    handleSelectChange(val, data) {
      this.selectPageInfo.pageindex = val;
      this.onSelectData(this.selectTableUrl, null, data);
			this.onCloseSelect();
    },
    //选项表格 api（专业）
    onSelectData(url, modal, data, row, prop, page) {
      this.selectTableUrl = url;
			myRequest({
        url: this.selectTableUrl,
        data: Object.assign(data || {}, this.selectPageInfo),
      }).then((res) => {
        if (res.data.code === "200") {
         this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
          this.selectTable = res.data.data;
          this[modal] = true;
        }
      });
    },
    //选项表格 - 搜索 click
    onSelectSearchClick(url, modal, data) {
      this.selectPageInfo.pageindex = 1;
      this.onSelectData(url, modal, data);
    },
    //关闭选项框 click
    onCloseSelect() {
      this.selectPageInfo.pageindex = 1;
    },

    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>

<style lang="scss">

</style>